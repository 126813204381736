import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../Assets/Logos/LittleStar-logo.png";
import Visa from "../../Assets/Icons/visa.png";
import Paystack from "../../Assets/Icons/paystack.png";
import Mastercard from "../../Assets/Icons/mastercard.png";
// import { fetchData } from '../../API/api';

const Donate = () => {
  const [donationType, setDonationType] = useState("one-time"); // Default donation type is one-time
  const [formData, setFormData] = useState({
    firstName: "", // Separate firstName
    lastName: "", // Separate lastName
    email: "",
    phone: "",
    amount: "",
    currency: "GHS", // Default to GHS currency
  });
  const [showConfirmation, setShowConfirmation] = useState(false); // Show confirmation form

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle change in donation type (one-time or monthly)
  const handleDonationTypeChange = (e) => {
    setDonationType(e.target.value);
    setShowConfirmation(false);
  };

  // Create the donation payload
  const createDonationPayload = (formData, donationType) => ({
    name: `${formData.firstName} ${formData.lastName}`,
    email: formData.email,
    phone: formData.phone,
    amount: formData.amount,
    currency: formData.currency,
    donationType,
  });

  // Post donation request to API
  const postDonationRequest = async (donationData) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/donate`;
      // console.log("API URL:", process.env.REACT_APP_API_URL);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(donationData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error processing one-time payment:", error);
      throw error;
    }
  };

  // Redirect to Paystack
  const redirectToPaystack = (authorizationUrl) => {
    window.location.href = authorizationUrl;
  };

  // Handle one-time payment
  const handleOneTimePayment = async () => {
    try {
      const payload = createDonationPayload(formData, donationType);
      const data = await postDonationRequest(payload);
      redirectToPaystack(data.authorization_url);
    } catch (error) {
      console.error("Error processing one-time payment:", error);
      alert("One-time donation failed. Please try again.");
    }
  };

  // Handle monthly payment
  const handleMonthlyPayment = async () => {
    try {
      const payload = createDonationPayload(formData, donationType);
      const data = await postDonationRequest(payload); // You can adjust this for monthly donations if needed
      redirectToPaystack(data.authorization_url);
    } catch (error) {
      console.error("Error processing monthly payment:", error);
      alert("Monthly donation failed. Please try again.");
    }
  };

  // Submit form based on donation type
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (donationType === "one-time") {
      handleOneTimePayment();
    } else if (donationType === "monthly") {
      handleMonthlyPayment();
    }
  };

  // Confirm button to proceed with the form submission
  const handleConfirm = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  return (
    <div className="donate-form">
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card shadow">
              <div className="card-header text-center donate-bg text-white d-flex align-items-center justify-content-center">
                <img
                  src={logo}
                  alt="LittleStar Logo"
                  className="me-2"
                  style={{ width: "40px", height: "30px" }}
                />
                <h5 className="m-0 text-black ts-4 fw-bold">
                  Donate to Little Star Charity Foundation
                </h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  {/* Personal Information */}
                  <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  {/* Email & Phone */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {/* Donation Type Selection */}
                  <div className="mb-3">
                    <label className="form-label">Donation Type</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="donationType"
                        id="oneTime"
                        value="one-time"
                        checked={donationType === "one-time"}
                        onChange={handleDonationTypeChange}
                      />
                      <label className="form-check-label" htmlFor="oneTime">
                        One-time
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="donationType"
                        id="monthly"
                        value="monthly"
                        checked={donationType === "monthly"}
                        onChange={handleDonationTypeChange}
                      />
                      <label className="form-check-label" htmlFor="monthly">
                        Monthly
                      </label>
                    </div>
                  </div>

                  {/* Conditional Rendering of One-time/Monthly Donation Form */}
                  {donationType === "one-time" && (
                    <>
                      <div className="mb-3">
                        <label htmlFor="amount" className="form-label">
                          Amount (GHC)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="amount"
                          name="amount"
                          value={formData.amount}
                          onChange={handleChange}
                          placeholder="Enter donation amount"
                          required
                        />
                      </div>
                      <div className="text-center mb-3">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={handleConfirm}
                        >
                          Confirm One-time Donation
                        </button>
                      </div>
                    </>
                  )}

                  {donationType === "monthly" && (
                    <>
                      <div className="mb-3">
                        <label htmlFor="amount" className="form-label">
                          Monthly Amount (GHC)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="amount"
                          name="amount"
                          value={formData.amount}
                          onChange={handleChange}
                          placeholder="Enter monthly donation amount"
                          required
                        />
                      </div>
                      <div className="text-center mb-3">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={handleConfirm}
                        >
                          Confirm Monthly Subscription
                        </button>
                      </div>
                    </>
                  )}

                  {/* Confirm and Donate */}
                  {showConfirmation && (
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-success btn-block"
                      >
                        Donate Now
                      </button>
                    </div>
                  )}

                  <hr className="my-4" />

                  {/* Secure Payments */}
                  <div className="text-center mb-3">
                    <p className="text-muted fw-bold">Secured by Paystack</p>
                  </div>
                  <div className="text-center">
                    <img
                      src={Paystack}
                      alt="Paystack"
                      className="me-2"
                      style={{ width: "35px", height: "30px" }}
                    />
                    <img
                      src={Visa}
                      alt="Visa"
                      className="me-2"
                      style={{ width: "75px", height: "30px" }}
                    />
                    <img
                      src={Mastercard}
                      alt="Mastercard"
                      style={{ width: "65px", height: "50px" }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
